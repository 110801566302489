export default {
    TIMEOUT_ERROR:
        'Ooops. We’re in the beta. Please be patient and try again later.',
    DEFAULT_ERROR: 'Something went wrong, try again later',
    INVALID_APPID: 'App ID is not valid',
    INVALID_CAPTCHA: 'Captcha is not valid',
    INVALID_CODE: 'Code is not valid',
    INVALID_EMAIL: 'Email is not valid',
    INVALID_NAME: 'Name is not valid',
    INVALID_REQUEST: 'Payload is not valid',
    INVALID_TYPE: 'Type is not valid',
    INVALID_URL: 'URL is not valid',
    INVALID_VERSION: 'Version is not valid',
}
